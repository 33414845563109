import styled from 'styled-components'
import FeaturedOne from '../assets/svg/featured-1.svg'
import FeaturedTwo from '../assets/svg/featured-2.svg'
import FeaturedThree from '../assets/svg/featured-3.svg'
import FeaturedFour from '../assets/svg/featured-4.svg'
import FeaturedFive from '../assets/svg/featured-5.svg'
import FeaturedSix from '../assets/svg/featured-6.svg'
import media from '../utils/media'
import Wrapper from './Wrapper'

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: var(--spacing-md);
  row-gap: var(--spacing-xxsm);

  ${media.sm`
    justify-content: space-between;
  `}
`

const Logo = styled.div`
  transform: scale(0.75);

  ${media.sm`
    transform: unset;
  `}
`

const PageBannerLogos = ({ className }) => {
  return (
    <Wrapper className={className}>
      <LogoContainer>
        <Logo>
          <FeaturedOne />
        </Logo>
        <Logo>
          <FeaturedTwo />
        </Logo>
        <Logo>
          <FeaturedThree />
        </Logo>
        <Logo>
          <FeaturedFour />
        </Logo>
        <Logo>
          <FeaturedFive />
        </Logo>
        <Logo>
          <FeaturedSix />
        </Logo>
      </LogoContainer>
    </Wrapper>
  )
}

export default PageBannerLogos
